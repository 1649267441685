<template>
  <w-icon v-bind="$attrs" v-on="$listeners">
    <rect
      x="0.425781"
      y="0.633301"
      width="31"
      height="31"
      rx="15.5"
      fill="#F8C150"
      stroke="transparent"
      fill-opacity="0.25"
    />
    <path
      d="M13.7446 4.995C13.7446 4.65258 14.0222 4.375 14.3646 4.375H17.3358C17.6782 4.375 17.9558 4.65258 17.9558 4.995V10.9036C17.9558 11.1699 18.1277 11.4035 18.3736 11.506C20.1327 12.2394 21.0122 13.6124 21.0122 15.6252C21.0122 17.7515 21.0122 16.3158 21.0122 27.2625C21.0122 27.605 20.7346 27.8917 20.3922 27.8917H11.4598C11.1174 27.8917 10.8398 27.6049 10.8398 27.2625C10.8398 16.3158 10.8398 17.7515 10.8398 15.6252C10.8398 13.6168 11.6721 12.2454 13.3366 11.5108C13.577 11.4047 13.7446 11.1737 13.7446 10.9109V4.995Z"
      stroke="#F8C150"
    />
  </w-icon>
</template>

<script>
import Icon from './Icon';

export default {
  components: {
    'w-icon': Icon,
  },
};
</script>
