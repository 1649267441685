<template>
  <w-icon>
    <path
      d="M26.2205 20.5095V24.5802C26.2205 25.3434 25.7081 26.0197 24.9504 26.1107C23.971 26.2284 22.9454 26.2276 22.1816 26.1676C8.79969 25.1156 5.7081 16.2482 5.32218 10.0001C5.248 8.79914 5.39963 7.38541 5.54794 6.37135C5.66373 5.57968 6.35355 5.01831 7.15365 5.01831H11.6568C12.3652 5.01831 12.9983 5.4602 13.2426 6.12509L14.5202 9.60183C14.7737 10.2915 14.5523 11.0659 13.9726 11.5175L12.4681 12.6894C11.9714 13.0763 11.7156 13.7137 11.914 14.3112C12.8692 17.1876 15.4365 18.9328 17.3577 19.7589C18.0751 20.0673 18.873 19.7163 19.2432 19.0287C19.5887 18.3871 20.3072 18.0399 21.0246 18.1678L24.8276 18.8462C25.6336 18.99 26.2205 19.6909 26.2205 20.5095Z"
    />
  </w-icon>
</template>

<script>
import Icon from './Icon';

export default {
  components: {
    'w-icon': Icon,
  },
};
</script>
