<template>
  <w-page layout="checkout-success" @close="handleClose">
    <div class="checkout-success">
      <component :is="iconComponent" class="checkout-success__icon"></component>
      <p class="checkout-success__thank-you">
        {{ thankYouText }}
      </p>
      <p v-if="!isQrOrder" class="checkout-success__fulfillment-time">
        {{ fulfillmentTimeText }}
      </p>
      <p v-if="showRestaurantAddress" class="checkout-success__address">
        <span class="checkout-success__name">{{ restaurantName }}</span>
        <span class="checkout-success__link-section">
          <w-location-icon></w-location-icon>
          <a
            class="checkout-success__link"
            :href="restaurantAddressLink"
            target="_blank"
          >
            {{ restaurantAddress }}
          </a>
        </span>
      </p>
      <p v-if="confirmationEmail">
        {{ confirmationEmailText }}
        <span class="checkout-success__link">{{ confirmationEmail }}</span>
      </p>
      <p v-if="showContactInformation">
        {{ contactText }}
      </p>
      <p v-if="showRestaurantPhone" class="checkout-success__phone">
        <w-phone-icon></w-phone-icon>
        <a class="checkout-success__link" :href="restaurantPhoneLink">{{
          restaurantPhone
        }}</a>
      </p>
      <p v-if="showRestaurantEmail" class="checkout-success__email">
        <w-email-icon></w-email-icon>
        <a class="checkout-success__link" :href="restaurantEmailLink">{{
          restaurantEmail
        }}</a>
      </p>
    </div>
  </w-page>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';

import i18n from '../../i18n';
import { Route, OrderType, QrOrderType } from '../../enums';

import Page from '../Page';
import {
  EmailIcon,
  PhoneIcon,
  PickupIcon,
  DeliveryIcon,
  LocationIcon,
  TableIcon,
  OrderIcon,
} from '../icons';

export default {
  data() {
    return {
      thankYouText: i18n.gettext('Thank you for your order!'),
      confirmationEmailText: i18n.gettext(
        'We have sent a confirmation email with your order details to'
      ),
    };
  },
  computed: {
    ...mapState(['facility', 'qrOrderType']),
    ...mapState('checkoutForm', {
      order: 'formValues',
    }),
    isPickupOrder() {
      return this.order.type === OrderType.Pickup;
    },
    isDeliveryOrder() {
      return this.order.type === OrderType.Delivery;
    },
    isQrOrder() {
      return this.order.type === OrderType.QrOrder;
    },
    iconComponent() {
      if (this.isPickupOrder) {
        return 'w-pickup-icon';
      }
      if (this.isDeliveryOrder) {
        return 'w-delivery-icon';
      }
      if (
        this.qrOrderType === QrOrderType.SeatOrder ||
        this.qrOrderType === QrOrderType.RoomOrder
      ) {
        return 'w-order-icon';
      }
      return 'w-table-icon';
    },
    fulfillmentTime() {
      return moment(this.order.fulfillment_time);
    },
    fulfillmentDuration() {
      return moment
        .duration(this.fulfillmentTime.diff(moment()))
        .humanize(true);
    },
    qrOrderTypeText() {
      let orderType = i18n.gettext('table');
      if (this.qrOrderType === QrOrderType.SeatOrder) {
        orderType = i18n.gettext('seat');
      } else if (this.qrOrderType === QrOrderType.RoomOrder) {
        orderType = i18n.gettext('room');
      }
      return orderType;
    },

    fulfillmentTimeText() {
      const isToday = this.fulfillmentTime.isSame(moment(), 'd');
      const time = this.fulfillmentTime.format('LT');
      const date = this.fulfillmentTime.format('dddd');
      const duration = this.fulfillmentDuration;
      if (this.isDeliveryOrder) {
        return isToday
          ? i18n.sprintf(
              i18n.gettext('Your food will be delivered at around %s (%s)'),
              time,
              duration
            )
          : i18n.sprintf(
              i18n.gettext('Your food will be delivered on %s at around %s'),
              date,
              time
            );
      }
      return isToday
        ? i18n.sprintf(
            i18n.gettext('You can pick up your food at around %s (%s)'),
            time,
            duration
          )
        : i18n.sprintf(
            i18n.gettext('You can pick up your food on %s at around %s'),
            date,
            time
          );
    },
    restaurantName() {
      return this.facility.name;
    },
    restaurantEmail() {
      return this.facility.email;
    },
    restaurantPhone() {
      return this.facility.phone;
    },
    restaurantEmailLink() {
      return `mailto:${this.restaurantEmail}`;
    },
    restaurantPhoneLink() {
      return `tel:${this.restaurantPhone}`;
    },
    confirmationEmail() {
      return this.order.email;
    },
    contactText() {
      if (this.isQrOrder) {
        return i18n.gettext(
          "If you have any questions, please don't hesitate to approach one of our waiters or waitresses!"
        );
      }
      return i18n.gettext('If you have any questions, please contact us:');
    },
    showContactInformation() {
      if (this.isQrOrder) {
        return true;
      }
      return !!(this.restaurantEmail || this.restaurantPhone);
    },
    showRestaurantPhone() {
      return !this.isQrOrder && !!this.restaurantPhone;
    },
    showRestaurantEmail() {
      return !this.isQrOrder && !!this.restaurantEmail;
    },
    googleMapsUrl() {
      return this.facility.google_maps_url;
    },
    restaurantAddress() {
      const { city, street, zip_code } = this.facility;
      if (!(street && city)) {
        return null;
      }
      return `${street},${zip_code ? ` ${zip_code}` : ''} ${city}`;
    },
    restaurantAddressLink() {
      return (
        this.googleMapsUrl ||
        `https://google.com/maps/search/${encodeURIComponent(
          this.restaurantAddress
        )}`
      );
    },
    showRestaurantAddress() {
      return this.isPickupOrder && !!this.restaurantAddress;
    },
  },
  methods: {
    ...mapActions(['resetCart']),
    handleClose() {
      this.$router.push({ name: Route.ProductList });
    },
  },
  beforeRouteLeave(to, from, next) {
    this.resetCart();
    next();
  },
  components: {
    'w-page': Page,
    'w-email-icon': EmailIcon,
    'w-phone-icon': PhoneIcon,
    'w-table-icon': TableIcon,
    'w-pickup-icon': PickupIcon,
    'w-location-icon': LocationIcon,
    'w-delivery-icon': DeliveryIcon,
    'w-order-icon': OrderIcon,
  },
};
</script>

<style lang="scss">
.checkout-success {
  text-align: center;
}

.checkout-success p {
  margin-bottom: 2rem;
}

.checkout-success p.checkout-success__thank-you {
  color: var(--app-theme__primaryColor);
  font-size: 1.5rem;
}

.checkout-success p.checkout-success__fulfillment-time {
  color: var(--app-theme__primaryColor);
}

.checkout-success__phone,
.checkout-success__email,
.checkout-success__address {
  color: var(--app-theme__primaryColor);
  display: flex;
  justify-content: center;
  .icon {
    margin-right: 0.25rem;
  }
}

.checkout-success__address {
  flex-direction: column;
}

.checkout-success__icon {
  color: var(--app-theme__primaryColor);
  height: 3.5rem;
  margin: 0 auto 1rem;
  width: 3.5rem;
}

.checkout-success__name {
  font-weight: 600;
}

.checkout-success__link-section {
  display: inline-flex;
  justify-content: center;
}

.checkout-success__link,
.checkout-success__link:hover {
  color: var(--app-theme__primaryColor);
}
</style>
