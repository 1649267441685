<template>
  <w-icon v-bind="$attrs" v-on="$listeners">
    <rect
      x="0.302734"
      y="0.633301"
      width="31"
      height="31"
      rx="15.5"
      fill="#E62C4F"
      stroke="transparent"
      fill-opacity="0.25"
    />
    <path
      d="M12.2683 9.7585C11.9648 8.14574 11.0367 7.21764 10.0437 6.78052M21.3028 9.75854C20.9993 8.14577 20.0712 7.21768 19.0782 6.78056M9.99038 14.8571C9.31247 10.5422 10.9623 9.87616 12.1503 9.78984C13.3771 9.70069 16.0313 9.61761 16.0313 14.8571C16.0313 20.0965 10.9151 25.1887 7.19052 25.4514C6.94767 25.5491 6.67157 25.4315 6.57383 25.1887C6.50983 25.0296 6.53668 24.8484 6.64403 24.7148L6.65658 24.6974C8.21609 22.544 10.6665 19.1604 9.99038 14.8571ZM19.0251 14.8571C18.3472 10.5422 19.997 9.87619 21.185 9.78986C22.4118 9.70071 25.066 9.61762 25.0659 14.8571C25.0659 20.0965 19.9498 25.1887 16.2252 25.4514C15.9824 25.5492 15.7063 25.4315 15.6085 25.1887C15.5445 25.0296 15.5714 24.8484 15.6787 24.7148L15.6913 24.6974C17.2508 22.544 19.7012 19.1604 19.0251 14.8571Z"
      stroke="#E62C4F"
    />
  </w-icon>
</template>

<script>
import Icon from './Icon';

export default {
  components: {
    'w-icon': Icon,
  },
};
</script>
