import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';

import i18n from '../../i18n';
import {
  OrderType,
  QrOrderType,
  PaymentType,
  StripeElement,
  StorageKey,
} from '../../enums';
import { extractErrorMessage, getAddressFromPlace } from '../../utils';
import { processFormField, validateFormField } from './utils';

export const getDefaultFormValues = () => ({
  address1: '',
  address2: '',
  zip_code: '',
  city: '',
  name: '',
  email: '',
  phone: '',
  notes: '',
  token: '', // payment token
  owner_name: '', // for sepa payments
  owner_email: '', // for sepa payments
  fulfillment_time: '',
  product_cart: '',
  payment_type: PaymentType.Cash,
  google_places_id: '',
  newsletter_subscribe: false,
  voucher: '',
});

export default ({ apiService, storageService }) => ({
  resetForm({ rootState, rootGetters, commit, dispatch }, values) {
    const { cart, source, googleMapsPlace } = rootState;

    const { paymentTypes, hasSelectedPickup, hasSelectedDelivery } =
      rootGetters;

    let prefilledValues;

    const previousOrder = storageService.get(StorageKey.Order);

    if (previousOrder) {
      const { name, phone, email } = previousOrder;
      prefilledValues = {
        name,
        phone,
        email,
      };
    } else {
      prefilledValues = {};
    }
    if (hasSelectedPickup) {
      const day = rootGetters['availabilities/pickupDays'][0];
      prefilledValues.fulfillment_day = day;
      prefilledValues.fulfillment_time = rootState.availabilities
        .availabilitiesByDay[day]
        ? rootState.availabilities.availabilitiesByDay[day][0]
        : '';
    } else if (hasSelectedDelivery) {
      const day = rootGetters['availabilities/deliveryDays'][0];
      prefilledValues.fulfillment_day = day;
      prefilledValues.fulfillment_time = rootState.availabilities
        .availabilitiesByDay[day]
        ? rootState.availabilities.availabilitiesByDay[day][0]
        : '';
      if (googleMapsPlace) {
        const { lat, lng } = googleMapsPlace.geometry.location;
        prefilledValues = {
          ...prefilledValues,
          ...getAddressFromPlace(googleMapsPlace),
          location: [lat, lng],
        };
      }
    } else {
      prefilledValues.address1 = rootState.qrModeLocation || '';
      prefilledValues.fulfillment_time = moment().format();
      if (previousOrder && previousOrder.type === OrderType.QrOrder) {
        prefilledValues.address1 = previousOrder.address1;
      }
    }

    commit('setFormValues', {
      ...getDefaultFormValues(),
      payment_type: paymentTypes[0],
      product_cart: cart.resource_uri,
      source,
      ...prefilledValues,
      ...values,
    });

    dispatch('resetVoucher');
    commit('setFormErrors', {});

    dispatch('clearTip');
  },
  setFormValue({ state, commit }, { name, value }) {
    commit('setFormValues', {
      ...state.formValues,
      [name]: value,
    });
    commit('setFormErrors', {
      ...state.formErrors,
      [name]: null,
    });
  },
  setFormError({ state, commit }, { name, value }) {
    commit('setFormErrors', {
      ...state.formErrors,
      [name]: value,
    });
  },
  setTip({ state, commit }, value) {
    commit('setTip', value);
  },
  clearTip({ state, commit }) {
    commit('setTipSelection', '');
    commit('setTip', null);
  },
  setTipSelection({ state, commit }, value) {
    commit('setTipSelection', value);
  },
  validateFormField({ state, getters, commit }, { name, value }) {
    commit('setFormErrors', {
      ...state.formErrors,
      [name]: validateFormField(
        name,
        processFormField(name, value),
        getters.validationContext
      ),
    });
  },
  async submitForm({
    state,
    getters,
    rootState,
    rootGetters,
    commit,
    dispatch,
  }) {
    const { formErrors } = state;
    const {
      formIsInvalid,
      validationErrors,
      serializedFormValues: values,
    } = getters;
    const {
      cart,
      facility,
      stripeInstance,
      stripeElements,
      rememberLastOrder,
      qrOrderType,
    } = rootState;
    const {
      hasSelectedPickup,
      hasSelectedDelivery,
      hasSelectedQrOrdering,
      isVoucherCoversFull,
    } = rootGetters;
    if (formIsInvalid) {
      const errors = {
        ...formErrors,
        ...validationErrors,
      };
      commit('setFormErrors', errors);
      return Promise.reject(errors);
    }
    let order = { ...values };
    if (getters.hasTip) {
      order.tip = Math.round(rootGetters.tipAmount * 100) / 100;
    }
    if (hasSelectedQrOrdering) {
      order.qr_order_type = rootState.qrOrderType;
    }
    if (isVoucherCoversFull) {
      order.payment_type = PaymentType.Voucher;
    }
    try {
      commit('setServerError', null);
      commit('setSubmitting', true);
      if (order.payment_type === PaymentType.CreditCard) {
        const result = await stripeInstance.createPaymentMethod({
          type: 'card',
          card: stripeElements[StripeElement.Card],
          billing_details: {
            name: order.owner_name,
          },
        });
        if (result.error) {
          throw new Error(result.error.message);
        }
        order.token = result.paymentMethod.id;
      } else if (order.payment_type === PaymentType.DirectDebit) {
        const result = await stripeInstance.createPaymentMethod({
          type: 'sepa_debit',
          sepa_debit: stripeElements[StripeElement.Iban],
          billing_details: {
            name: order.owner_name,
            email: order.owner_email,
          },
        });
        if (result.error) {
          throw new Error(result.error.message);
        }
        order.token = result.paymentMethod.id;
      }
      const createOrderResponse = await apiService.createOrder(order, cart);
      if (
        createOrderResponse.payment &&
        createOrderResponse.payment.paymentState === 'REQUIRES_USER_ACTION'
      ) {
        const { error, paymentIntent } = await stripeInstance.handleCardAction(
          createOrderResponse.payment['additional_info']['client_secret']
        );
        if (error) {
          throw new Error(error.message);
        }
        order = await apiService.completeOrderPurchase(
          createOrderResponse,
          paymentIntent.id
        );
      } else {
        order = createOrderResponse;
      }
      dispatch('resetForm', order); // reset form with order data needed on success screen
      commit('setForbiddenProducts', [], { root: true }); // maybe there's a better place to do this
      if (rememberLastOrder) {
        storageService.set(StorageKey.Order, order);
      } else {
        storageService.remove(StorageKey.Order);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.menu_widget_order
      ) {
        for (const [name, value] of Object.entries(
          error.response.data.menu_widget_order
        )) {
          switch (name) {
            case 'fulfillment_time':
              if (hasSelectedQrOrdering) {
                commit(
                  'setServerError',
                  i18n.sprintf(
                    i18n.gettext(
                      "Unfortunately we don't accept any %s at the moment."
                    ),
                    qrOrderType === QrOrderType.RoomOrder
                      ? i18n.gettext('room orders')
                      : qrOrderType === QrOrderType.SeatOrder
                        ? i18n.gettext('seat orders')
                        : i18n.gettext('table orders')
                  )
                );
                break;
              }
              await dispatch('availabilities/fetchAvailableDays', undefined, {
                root: true,
              });
              const days = rootGetters['availabilities/orderDays'];
              if (days.length === 0) {
                commit(
                  'setServerError',
                  hasSelectedPickup
                    ? i18n.gettext(
                        "Unfortunately we don't accept any takeaway orders at the moment."
                      )
                    : i18n.gettext(
                        "Unfortunately we don't accept any delivery orders at the moment."
                      )
                );
                break;
              }
              if (!days.includes(state.formValues.fulfillment_day)) {
                dispatch('setFormValue', {
                  name: 'fulfillment_day',
                  value: '',
                });
                dispatch('setFormError', {
                  name: 'fulfillment_day',
                  value: hasSelectedPickup
                    ? i18n.gettext(
                        'The chosen takeaway day is no longer available. Please select another day.'
                      )
                    : i18n.gettext(
                        'The chosen delivery day is no longer available. Please select another day.'
                      ),
                });
                break;
              }
              dispatch('setFormValue', { name: 'fulfillment_time', value: '' });
              dispatch('setFormError', {
                name: 'fulfillment_time',
                value: hasSelectedPickup
                  ? i18n.gettext(
                      'The chosen takeaway time is no longer available. Please select another time.'
                    )
                  : i18n.gettext(
                      'The chosen delivery time is no longer available. Please select another time.'
                    ),
              });
              dispatch(
                'availabilities/fetchAvailabilities',
                state.formValues.fulfillment_day,
                { root: true }
              );
              break;
            case 'forbidden_products':
              commit('setForbiddenProducts', value, { root: true });
              break;
            case 'location':
              commit(
                'setServerError',
                i18n.gettext("Unfortunately we can't deliver to your location.")
              );
              break;
            default:
              if (typeof state.formValues[name] !== 'undefined') {
                // make sure the error can be corrected
                dispatch('setFormError', { name, value });
              } else {
                commit('setServerError', value);
              }
          }
        }
      } else {
        commit('setServerError', extractErrorMessage(error));
      }
      throw error;
    } finally {
      commit('setSubmitting', false);
    }
  },
  async checkVoucher({ state, commit, rootGetters }) {
    commit('setVoucher', null, { root: true });
    const ref_num = state.formValues.voucher;
    if (state.formValues.voucher) {
      const voucher = await apiService.checkVoucher(ref_num);
      if (Object.keys(voucher).length > 0) {
        if (voucher.status === 'claimed') {
          commit('setFormErrors', {
            ...state.formErrors,
            voucher: i18n.gettext(
              'The entire voucher amount has already been redeemed.'
            ),
          });
        } else {
          if (voucher.amount_left >= rootGetters.cartTotal) {
            commit('setFormValues', {
              ...state.formValues,
              payment_type: PaymentType.Cash,
            });
          }
          commit('setVoucher', voucher, { root: true });
        }
      } else {
        commit('setFormErrors', {
          ...state.formErrors,
          voucher: i18n.gettext('This is not a valid voucher code.'),
        });
      }
    }
  },
  resetVoucher({ state, commit }) {
    commit('setFormValues', {
      ...state.formValues,
      voucher: '',
    });
    commit('setFormErrors', {
      ...state.formErrors,
      voucher: '',
    });
    commit('setVoucher', null, { root: true });
  },
});
