<template>
  <w-icon v-bind="$attrs" v-on="$listeners">
    <rect
      x="0.425781"
      y="0.633301"
      width="31"
      height="31"
      rx="15.5"
      fill="#BC213F"
      stroke="transparent"
      fill-opacity="0.25"
    />
    <path
      d="M10.4676 10.6821C10.1894 9.2041 9.33886 8.35355 8.42889 7.95296M17.9347 10.682C17.6565 9.20403 16.806 8.35348 15.896 7.95288M25.3886 10.682C25.1104 9.20403 24.2599 8.35348 23.3499 7.95288M8.38028 15.3547C7.75903 11.4003 9.27098 10.7899 10.3597 10.7108C11.484 10.6291 13.1491 10.553 13.1491 15.3546C13.1491 20.1563 9.22768 24.823 5.81435 25.0638C5.59179 25.1534 5.33876 25.0456 5.24919 24.823C5.19053 24.6773 5.21515 24.5112 5.31352 24.3887L5.32503 24.3728C6.75422 22.3993 8.99986 19.2984 8.38028 15.3547ZM15.8472 15.3546C15.226 11.4003 16.7379 10.7899 17.8267 10.7108C18.951 10.6291 20.616 10.5529 20.616 15.3546C20.616 20.1563 16.6946 24.8229 13.2813 25.0638C13.0587 25.1533 12.8057 25.0455 12.7161 24.823C12.6575 24.6772 12.6821 24.5112 12.7805 24.3887L12.792 24.3728C14.2212 22.3993 16.4668 19.2984 15.8472 15.3546ZM23.3013 15.3547C22.6801 11.4003 24.192 10.79 25.2807 10.7109C26.405 10.6292 28.0701 10.553 28.0701 15.3547C28.0701 20.1564 24.1487 24.823 20.7354 25.0638C20.5128 25.1534 20.2598 25.0456 20.1702 24.823C20.1116 24.6773 20.1362 24.5112 20.2345 24.3887L20.246 24.3728C21.6752 22.3993 23.9209 19.2985 23.3013 15.3547Z"
      stroke="#BC213F"
    />
  </w-icon>
</template>

<script>
import Icon from './Icon';

export default {
  components: {
    'w-icon': Icon,
  },
};
</script>
