<template>
  <div class="checkout-form">
    <w-form-group v-if="hasSelectedDelivery">
      <w-form-field
        name="address1"
        :label="address1Text"
        :disabled="disableAddress"
      ></w-form-field>
      <w-form-field
        name="zip_code"
        :label="zipCodeText"
        :disabled="disableAddress"
      ></w-form-field>
      <w-form-field
        name="city"
        :label="cityText"
        :disabled="disableAddress"
      ></w-form-field>
      <w-form-field name="address2" :label="address2Text"></w-form-field>
    </w-form-group>
    <w-form-group
      v-if="
        !hasSelectedQrOrdering ||
        menu.qr_address_required ||
        menu.qr_name_required ||
        menu.qr_email_required ||
        menu.qr_phone_required
      "
    >
      <w-form-field
        v-if="hasSelectedQrOrdering && menu.qr_address_required"
        name="address1"
        :label="tableText"
        :disabled="disableAddress"
      ></w-form-field>
      <w-form-field
        v-if="!hasSelectedQrOrdering || menu.qr_name_required"
        name="name"
        :label="nameText"
      ></w-form-field>
      <w-form-field
        v-if="!hasSelectedQrOrdering || menu.qr_email_required"
        name="email"
        type="email"
        :label="emailText"
      ></w-form-field>
      <w-phone-form-field
        v-if="!hasSelectedQrOrdering || menu.qr_phone_required"
        name="phone"
        :label="phoneText"
      ></w-phone-form-field>
    </w-form-group>
    <w-form-group>
      <w-form-field
        v-if="!hasSelectedQrOrdering && canOrderInAdvance"
        name="fulfillment_day"
        :label="fulfillmentDayText"
        :disabled="isFetchingAvailabilities"
      >
        <template #default="{ inputProps, inputListeners }">
          <select
            class="select"
            name="fulfillment_day"
            v-bind="inputProps"
            @input="onDateChange"
            v-on="inputListeners"
          >
            <option
              v-for="option in fulfillmentDayOptions"
              :key="option.label"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </select>
        </template>
      </w-form-field>
      <w-form-field
        v-if="!hasSelectedQrOrdering"
        name="fulfillment_time"
        :label="fulfillmentTimeText"
        :disabled="isFetchingAvailabilities"
      >
        <template #default="{ inputProps, inputListeners }">
          <select
            class="select"
            name="fulfillment_time"
            v-bind="inputProps"
            v-on="inputListeners"
          >
            <option
              v-for="option in fulfillmentTimeOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </select>
        </template>
      </w-form-field>
      <w-form-field name="notes" :label="notesText" grow>
        <template #default="{ inputProps, inputListeners }">
          <textarea
            rows="1"
            v-bind="inputProps"
            v-on="inputListeners"
          ></textarea>
        </template>
      </w-form-field>
      <div
        class="checkout-form__check-button-container"
        v-if="shouldShowVoucherField"
      >
        <w-form-field name="voucher" :label="voucherCodeText"> </w-form-field>
        <div v-if="shouldShowVoucherClear">
          <w-close-icon
            @click="onVoucherClear"
            class="checkout-form__clean-voucher-button"
          ></w-close-icon>
        </div>
        <w-button
          class="checkout-form__check-button"
          primary
          hollow
          @click="onCheck"
        >
          {{ checkText }}
        </w-button>
      </div>
      <div
        v-if="shouldShowVoucherAmountText"
        class="checkout-form__voucher-code-text"
      >
        {{ voucherIsValidText }}
      </div>
    </w-form-group>
    <w-form-field bordered :label="paymentMethodText">
      <w-radio-input
        v-if="canPayCash"
        :value="PT.Cash"
        :checked="payCash"
        @change="changePaymentType"
      >
        <div>{{ cashText }}</div>
        <div
          v-if="showCashPaymentsCustomText"
          class="checkout-form__cash-payments-custom-text"
        >
          {{ cashPaymentsCustomText }}
        </div>
      </w-radio-input>
      <div v-if="canPayWithPayPal">
        <w-radio-input
          :value="PT.PayPal"
          :checked="payWithPayPal"
          @change="changePaymentType"
        >
          {{ payPalText }}
        </w-radio-input>
        <div v-if="payWithPayPal && tipsEnabled">
          <w-tip-element></w-tip-element>
        </div>
      </div>
      <w-radio-input
        v-if="canPayWithCreditCard"
        :value="PT.CreditCard"
        :checked="payWithCreditCard"
        @change="changePaymentType"
      >
        {{ creditCardText }}
      </w-radio-input>
      <div v-if="payWithCreditCard">
        <div v-if="tipsEnabled">
          <w-tip-element></w-tip-element>
        </div>
        <w-form-field
          class="checkout-form__credit-card-holder"
          name="owner_name"
          :label="cardHolderNameText"
        ></w-form-field>
        <w-stripe-element
          :key="SE.Card"
          :type="SE.Card"
          :options="stripeCardOptions"
        ></w-stripe-element>
      </div>
      <w-radio-input
        v-if="canPayWithDirectDebit"
        :value="PT.DirectDebit"
        :checked="payWithDirectDebit"
        @change="changePaymentType"
      >
        {{ directDebitText }}
      </w-radio-input>
      <div v-if="payWithDirectDebit">
        <div v-if="tipsEnabled">
          <w-tip-element></w-tip-element>
        </div>
        <w-form-group class="checkout-form__sepa-details">
          <w-form-field
            name="owner_name"
            :label="accountHolderNameText"
          ></w-form-field>
          <w-form-field
            name="owner_email"
            type="email"
            :label="accountHolderEmailText"
          ></w-form-field>
        </w-form-group>
        <w-stripe-element
          :key="SE.Iban"
          :type="SE.Iban"
          :options="stripeIbanOptions"
        ></w-stripe-element>
      </div>
      <w-radio-input
        v-if="canPayWithBambora"
        :value="PT.Bambora"
        :checked="payWithBambora"
        @change="changePaymentType"
      >
        {{ creditCardText }}
      </w-radio-input>
      <div v-if="payWithBambora && tipsEnabled">
        <w-tip-element></w-tip-element>
      </div>
    </w-form-field>
    <div class="checkout-form__checkbox-container">
      <w-checkbox-input
        v-if="showNewsletterSignup"
        class="checkout-form__checkbox"
        :checked="formValues.newsletter_subscribe"
        @change="toggleNewsletterSubscribe"
      >
        {{ newsletterSignupText }}
      </w-checkbox-input>
      <w-checkbox-input
        class="checkout-form__checkbox"
        :checked="rememberLastOrder"
        @change="toggleRememberLastOrder"
      >
        {{ rememberText }}
      </w-checkbox-input>
    </div>
    <w-checkout-message class="checkout-form__message"></w-checkout-message>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';

import i18n from '../../i18n';
import { PaymentType, StripeElement as SE, QrOrderType } from '../../enums';

import RadioInput from '../RadioInput';
import CheckboxInput from '../CheckboxInput';
import Button from '../Button';
import { CheckoutMessage } from '../messages';

import FormField from './FormField';
import FormGroup from './FormGroup';
import StripeElement from './StripeElement';
import TipElement from './TipElement';
import { CloseIcon } from '../icons';
import PhoneFormField from './PhoneFormField';

export default {
  data() {
    return {
      address1Text: i18n.gettext('Street and number'),
      address2Text: i18n.gettext('Additional address information'),
      zipCodeText: i18n.gettext('Zip code'),
      cityText: i18n.gettext('City'),
      nameText: i18n.gettext('Name'),
      emailText: i18n.gettext('Email'),
      phoneText: i18n.gettext('Phone'),
      notesText: i18n.gettext('Notes'),
      paymentMethodText: i18n.gettext('Payment Method'),
      voucherCodeText: i18n.gettext('Voucher Code'),
      checkText: i18n.gettext('Check'),
      payPalText: i18n.gettext('PayPal'),
      creditCardText: i18n.gettext('Credit card'),
      directDebitText: i18n.gettext('SEPA direct debit'),
      cardHolderNameText: i18n.gettext('Card holder name'),
      accountHolderNameText: i18n.gettext('Account holder name'),
      accountHolderEmailText: i18n.gettext('Account holder email'),
      rememberText: i18n.gettext(
        'Remember personal information for next order'
      ),
      isFetchingAvailabilities: false,
    };
  },
  computed: {
    ...mapState([
      'facility',
      'qrOrderType',
      'qrModeLocation',
      'rememberLastOrder',
      'newsletterSignup',
      'qrModeType',
      'isOrderbirdFacility',
    ]),
    ...mapGetters([
      'menu',
      'getPriceTag',
      'paymentTypes',
      'canPayCash',
      'canPayWithPayPal',
      'canPayWithBambora',
      'canPayWithCreditCard',
      'canPayWithDirectDebit',
      'hasSelectedPickup',
      'hasSelectedDelivery',
      'hasSelectedQrOrdering',
      'qrCashPaymentText',
      'tipsEnabled',
      'hasDeliveryAreas',
      'isVoucherCoversFull',
      'voucherEnabled',
      'voucherAmount',
    ]),
    ...mapState('availabilities', ['availabilitiesByDay']),
    ...mapGetters('availabilities', [
      'orderDays',
      'canOrderToday',
      'canOrderInAdvance',
    ]),
    ...mapState('checkoutForm', ['formValues', 'formErrors']),
    ...mapGetters('checkoutForm', [
      'payCash',
      'payWithPayPal',
      'payWithBambora',
      'payWithCreditCard',
      'payWithDirectDebit',
    ]),
    PT() {
      return PaymentType;
    },
    SE() {
      return SE;
    },
    shouldShowVoucherClear() {
      return Boolean(this.formValues.voucher);
    },
    shouldShowVoucherAmountText() {
      return this.voucherAmount > 0;
    },
    shouldShowVoucherField() {
      return this.voucherEnabled;
    },
    tableText() {
      let orderType = i18n.gettext('Table');
      if (this.qrOrderType === QrOrderType.SeatOrder) {
        orderType = i18n.gettext('Seat');
      } else if (this.qrOrderType === QrOrderType.RoomOrder) {
        orderType = i18n.gettext('Room');
      }
      return orderType;
    },
    cashText() {
      return this.hasSelectedQrOrdering
        ? i18n.gettext('Payment to service personnel')
        : this.hasSelectedPickup
          ? i18n.gettext('Payment on site')
          : i18n.gettext('Payment upon delivery');
    },
    cashPaymentsCustomText() {
      return this.qrCashPaymentText;
    },
    showCashPaymentsCustomText() {
      return this.hasSelectedQrOrdering && !!this.cashPaymentsCustomText;
    },
    stripeCardOptions() {
      return {
        hidePostalCode: true,
      };
    },
    stripeIbanOptions() {
      return {
        supportedCountries: ['SEPA'],
      };
    },
    fulfillmentTimeOptions() {
      const availabilities =
        this.availabilitiesByDay[this.formValues.fulfillment_day];
      if (!availabilities) {
        return [];
      }
      return availabilities.map((date) => ({
        label: moment(date).format('HH:mm'),
        value: date,
      }));
    },
    fulfillmentTimeText() {
      if (this.hasSelectedPickup) {
        return i18n.gettext('Takeaway time');
      }
      if (this.hasSelectedDelivery) {
        return i18n.gettext('Delivery time');
      }
      return '';
    },
    fulfillmentDayOptions() {
      return this.orderDays.map((day) => ({
        label: moment(day).format('ddd, LL'),
        value: day,
      }));
    },
    fulfillmentDayText() {
      if (this.hasSelectedPickup) {
        return i18n.gettext('Takeaway date');
      }
      if (this.hasSelectedDelivery) {
        return i18n.gettext('Delivery date');
      }
      return '';
    },
    disableAddress() {
      return (
        (this.hasSelectedDelivery && this.hasDeliveryAreas) ||
        (this.hasSelectedQrOrdering && !!this.qrModeLocation)
      );
    },
    showPaymentMethod() {
      return !this.isVoucherCoversFull;
    },
    newsletterSignupText() {
      const newsletterSignupText = this.facility.widget_newsletter_signup_text;
      if (newsletterSignupText) {
        return newsletterSignupText.replaceAll('%s', this.facility.name);
      }
      return i18n.sprintf(
        i18n.gettext('I want to receive exclusive offers via email from %s.'),
        this.facility.name
      );
    },
    showNewsletterSignup() {
      if (this.hasSelectedQrOrdering && !this.menu.qr_email_required) {
        return false;
      }
      return this.newsletterSignup;
    },
    voucherIsValidText() {
      return i18n.sprintf(
        i18n.gettext('Voucher code is valid. Value: %s'),
        this.getPriceTag(this.voucherAmount)
      );
    },
  },
  watch: {
    paymentTypes(paymentTypes) {
      if (this.payCash && !paymentTypes.includes(PaymentType.Cash)) {
        this.setFormValue({
          name: 'payment_type',
          value: '',
        });
        this.setFormError({
          name: 'payment_type',
          value: i18n.sprintf(
            i18n.gettext(
              'Cash payments are disabled for orders over %s. Please choose another payment method.'
            ),
            this.getPriceTag(this.menu.force_prepayment_order_value)
          ),
        });
      } else {
        if (!this.formValues.payment_type) {
          this.setFormValue({ name: 'payment_type', value: paymentTypes[0] });
        }
        if (this.formErrors.payment_type) {
          this.setFormError({ name: 'payment_type', value: null });
        }
      }
    },
    payWithDirectDebit(checked) {
      this.setFormValue({
        name: 'owner_name',
        value: checked ? this.formValues.name : '',
      });
      this.setFormValue({
        name: 'owner_email',
        value: checked ? this.formValues.email : '',
      });
    },
  },
  methods: {
    ...mapActions(['toggleRememberLastOrder']),
    ...mapActions('availabilities', ['fetchAvailabilities']),
    ...mapActions('checkoutForm', [
      'setFormValue',
      'setFormError',
      'checkVoucher',
      'resetVoucher',
    ]),
    async onDateChange(e) {
      this.setFormValue({ name: 'fulfillment_day', value: e.target.value });
      this.isFetchingAvailabilities = true;
      try {
        const availabilities = await this.fetchAvailabilities(e.target.value);
        this.setFormValue({
          name: 'fulfillment_time',
          value: availabilities[0],
        });
      } catch {}
      this.isFetchingAvailabilities = false;
    },
    toggleNewsletterSubscribe() {
      this.setFormValue({
        name: 'newsletter_subscribe',
        value: !this.formValues.newsletter_subscribe,
      });
    },
    changePaymentType(e) {
      this.setFormValue({
        name: 'payment_type',
        value: e.target.value,
      });
    },
    onCheck() {
      this.checkVoucher(this.formValues.voucher);
    },
    onVoucherClear() {
      this.resetVoucher();
    },
  },
  components: {
    'w-form-field': FormField,
    'w-form-group': FormGroup,
    'w-radio-input': RadioInput,
    'w-checkbox-input': CheckboxInput,
    'w-stripe-element': StripeElement,
    'w-tip-element': TipElement,
    'w-checkout-message': CheckoutMessage,
    'w-button': Button,
    'w-close-icon': CloseIcon,
    'w-phone-form-field': PhoneFormField,
  },
};
</script>

<style lang="scss">
@import '../../styles/_variables.scss';
.form-field .radio-input {
  margin-bottom: 1rem;
}

.checkout-form__sepa-details {
  padding-bottom: 0;
}

.checkout-form__credit-card-holder,
.checkout-form__sepa-details .form-field {
  input:not([type='radio']):not([type='checkbox']) {
    background-color: var(--app-theme__menuColor);
    border: none;
    border-radius: 0;
  }
}

.checkout-form__message {
  margin-top: 3rem;
}

.checkout-form__checkbox-container {
  padding-top: 2rem;
}

.checkout-form__checkbox {
  margin-top: 1rem;
}

.checkout-form__cash-payments-custom-text {
  margin-top: 0.5rem;
  word-break: break-word;
}
.checkout-form__check-button-container {
  display: flex;
  width: 100%;
  .form-field {
    width: 100%;
  }
}
.checkout-form__voucher-code-text {
  color: var(--app-theme__primaryColor);
  margin-left: 0.5rem;
  margin-top: -0.7rem;
}
.checkout-form__check-button {
  height: 3.6rem;
  margin-top: 2rem;
  width: 100px;
  margin-left: 0.5rem;
}
.checkout-form__clean-voucher-button {
  height: 58px;
  color: var(--app-theme__lineColor);
  margin-top: 48px;
  margin-left: -42px;
  cursor: pointer;
  @media (max-width: 1023px) {
    margin-top: 42px;
    margin-left: -30px;
  }
}
</style>
