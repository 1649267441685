<template>
  <w-icon v-bind="$attrs" v-on="$listeners">
    <rect
      x="0.226074"
      y="0.633301"
      width="31"
      height="31"
      rx="15.5"
      fill="#22CCAA"
      stroke="transparent"
      fill-opacity="0.25"
    />
    <path
      d="M18.1621 21.684C17.2718 23.1361 16.7133 25.4425 16.7133 25.4425M14.6424 20.475C14.6424 20.475 16.7084 23.1859 16.7084 25.7514M18.6915 20.9795C18.6915 20.9795 22.9339 22.0341 24.719 18.7935C26.5042 15.5529 25.2063 11.3708 25.2063 11.3708C23.4732 11.3708 20.1766 12.6769 18.6915 15.3728C16.9064 18.6134 18.6915 20.9795 18.6915 20.9795ZM14.0634 19.8755C14.0634 19.8755 8.74808 21.5017 6.66991 17.4078C4.59174 13.3139 7.23514 7.96387 7.23514 7.96387C9.36093 8.48239 13.3527 9.63906 14.906 12.6989C16.9841 16.7928 14.0634 19.8755 14.0634 19.8755Z"
      stroke="#22CCAA"
    />
  </w-icon>
</template>

<script>
import Icon from './Icon';

export default {
  components: {
    'w-icon': Icon,
  },
};
</script>
