<template>
  <w-icon viewBox="0 0 32 32">
    <path
      d="M6.47455 20.4049H28.6783M7.39971 19.9205V18.9243C7.39971 13.4061 11.8731 8.93263 17.3914 8.93263C22.9097 8.93263 27.3831 13.4061 27.3831 18.9243V19.9205M2.77393 9.30304H6.47455M2.77393 16.7043H4.62424M2.77393 13.0037H4.62424M18.3193 7.32442C18.3193 7.83744 17.9034 8.25333 17.3903 8.25333C16.8773 8.25333 16.4614 7.83744 16.4614 7.32442C16.4614 6.8114 16.8773 6.39551 17.3903 6.39551C17.9034 6.39551 18.3193 6.8114 18.3193 7.32442ZM24.9775 23.22C24.9775 24.5533 23.8966 25.6341 22.5633 25.6341C21.2301 25.6341 20.1492 24.5533 20.1492 23.22C20.1492 21.8867 21.2301 20.8059 22.5633 20.8059C23.8966 20.8059 24.9775 21.8867 24.9775 23.22ZM15.0579 23.22C15.0579 24.5533 13.9771 25.6341 12.6438 25.6341C11.3105 25.6341 10.2297 24.5533 10.2297 23.22C10.2297 21.8867 11.3105 20.8059 12.6438 20.8059C13.9771 20.8059 15.0579 21.8867 15.0579 23.22Z"
    />
  </w-icon>
</template>

<script>
import Icon from './Icon';

export default {
  components: {
    'w-icon': Icon,
  },
};
</script>
