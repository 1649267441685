import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import queryString from 'query-string';

import { getHostname } from '../app/utils/url';
import { loadPayPalSDK, loadStripeSDK } from '../common/utils';

import './styles/index.scss';

import {
  apiService,
  placesService,
  storageService,
  sessionStorageService,
} from './services';
import { parseFacilityId } from './utils';
import { StorageKey } from './enums';

import createStore from './store';
import createTheme from './createTheme';
import createRouter from './createRouter';
import createStyleSheet from './createStyleSheet';

import configureSentry from './configureSentry';
import configureMoment from './configureMoment';
import configureStyles from './configureStyles';

import App from './components/App';

configureSentry(window.resmioApp);
configureMoment(window.resmioApp);

const { staticUrl, isOrderbirdFacility } = window.resmioApp;

const facilityId = parseFacilityId(window.location.pathname);

const queryParams = queryString.parse(window.location.search);

const storedQueryParams = sessionStorageService.get(StorageKey.Params);

const params = {
  qr_order_type: storedQueryParams && storedQueryParams.qr_order_type,
  ...queryParams,
};

const themeParams = {
  qr_order_type: storedQueryParams && storedQueryParams.qr_order_type,
  ...window.resmioApp,
};

sessionStorageService.set(StorageKey.Params, params);

const theme = createTheme(themeParams);

let iframe;
try {
  iframe = !!JSON.parse(params.iframe);
} catch {
  iframe = window.self !== window.top;
}

let qrModeEnabled;
let qrOrderType;
let qrModeLocation;
try {
  qrModeEnabled = !!params.qr_order_type;
  qrOrderType = params.qr_order_type;
  qrModeLocation = params.qr_order_location;
} catch {
  qrModeEnabled = false;
}

let newsletterSignup;
try {
  newsletterSignup = !!JSON.parse(params.newsletter_signup);
} catch {
  newsletterSignup = true;
}

const store = createStore(
  {
    apiService,
    placesService,
    storageService,
  },
  {
    theme,
    source: getHostname(params.source || window.location.href),
    iframe,
    preview:
      iframe && window.document.referrer.startsWith(window.location.origin),
    qrModeEnabled,
    qrOrderType,
    qrModeLocation,
    facility: {
      id: facilityId,
    },
    staticUrl,
    isOrderbirdFacility,
    newsletterSignup,
  }
);

apiService.addEventListener('fetchstart', () =>
  store.commit('setFetching', true)
);
apiService.addEventListener('fetchend', () =>
  store.commit('setFetching', false)
);

const router = createRouter({ base: `/${facilityId}/menu-widget`, store });

configureStyles(window.resmioApp);

window.document.addEventListener('DOMContentLoaded', async () => {
  const styleTag = window.document.createElement('style');
  styleTag.innerHTML = createStyleSheet(theme);
  window.document.body.appendChild(styleTag);

  if (window.resmioApp.paypal_client_id) {
    loadPayPalSDK({
      clientId: window.resmioApp.paypal_client_id,
      currency: window.resmioApp.currency,
    });
  }

  if (window.resmioApp.stripe_publish_key) {
    loadStripeSDK().then(() =>
      store.commit(
        'setStripeInstance',
        window.Stripe(window.resmioApp.stripe_publish_key)
      )
    );
  }

  await store.dispatch('initialize');

  new Vue({
    store,
    router,
    render: (h) => h(App),
  }).$mount('#app');
});

// used for CDN fallback detection
window.resmioLoaded = true;
