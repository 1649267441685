<template>
  <w-message
    v-if="showMessage"
    :alert="!canOrderToday && canOrderInAdvance"
    :error="!(canOrderToday || canOrderInAdvance)"
  >
    {{ message }}
  </w-message>
</template>

<script>
import { mapGetters } from 'vuex';

import i18n from '../../i18n';

import Message from './Message';

export default {
  computed: {
    ...mapGetters([
      'pickupEnabled',
      'deliveryEnabled',
      'hasSelectedPickup',
      'hasSelectedDelivery',
    ]),
    ...mapGetters('availabilities', [
      'canPickupToday',
      'canDeliverToday',
      'canOrderToday',
      'canOrderInAdvance',
    ]),
    message() {
      if (
        this.hasSelectedPickup &&
        this.deliveryEnabled &&
        this.canDeliverToday
      ) {
        return `${this.baseMessage} ${i18n.gettext(
          'please consider switching to delivery!'
        )}`;
      }
      if (
        this.hasSelectedDelivery &&
        this.pickupEnabled &&
        this.canPickupToday
      ) {
        return `${this.baseMessage} ${i18n.gettext(
          'please consider switching to pickup!'
        )}`;
      }
      return this.canOrderInAdvance
        ? `${this.baseMessage} ${i18n.gettext(
            'but you can order for another day!'
          )}`
        : `${this.baseMessage} ${i18n.gettext(
            'please check back another day!'
          )}`;
    },
    baseMessage() {
      if (this.hasSelectedPickup && !this.canPickupToday) {
        return i18n.gettext(
          "Please note that we can't take any more takeaway orders today,"
        );
      }
      if (this.hasSelectedDelivery && !this.canDeliverToday) {
        return i18n.gettext(
          "Please note that we can't take any more delivery orders today,"
        );
      }
      return i18n.gettext(
        "Please note that we can't take any table orders at the moment,"
      );
    },
    showMessage() {
      return !this.canOrderToday;
    },
  },
  components: {
    'w-message': Message,
  },
};
</script>
