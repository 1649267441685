<template>
  <w-icon>
    <path
      d="M5.20801 7.23912L14.8757 15.6701C15.6301 16.3279 16.7547 16.3268 17.5077 15.6675L27.4908 6.92676M7.29297 24.7788H25.6357C27.0165 24.7788 28.1357 23.6595 28.1357 22.2788V8.73584C28.1357 7.35513 27.0165 6.23584 25.6357 6.23584H7.29297C5.91226 6.23584 4.79297 7.35513 4.79297 8.73584V22.2788C4.79297 23.6595 5.91226 24.7788 7.29297 24.7788Z"
    />
  </w-icon>
</template>

<script>
import Icon from './Icon';

export default {
  components: {
    'w-icon': Icon,
  },
};
</script>
