<template>
  <w-icon v-bind="$attrs" v-on="$listeners">
    <rect
      x="0.503418"
      y="0.633301"
      width="31"
      height="31"
      rx="15.5"
      fill="#FF910A"
      stroke="transparent"
      fill-opacity="0.25"
    />
    <path
      d="M17.5476 8.98252C17.2173 7.22768 16.2075 6.21782 15.127 5.74219M15.0693 14.5302C14.3317 9.8352 16.1268 9.11052 17.4195 9.01659C18.7544 8.91958 21.6424 8.82918 21.6424 14.5302C21.6424 19.8639 16.7698 25.0573 12.8232 25.9385C12.2993 26.0555 11.5522 26.27 11.3518 25.772V25.772C11.2821 25.599 11.3113 25.4018 11.4281 25.2564L11.4418 25.2375C13.1387 22.8944 15.8049 19.2127 15.0693 14.5302Z"
      stroke="#FF910A"
    />
  </w-icon>
</template>

<script>
import Icon from './Icon';

export default {
  components: {
    'w-icon': Icon,
  },
};
</script>
